import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MissingPageService } from '../../services/missing-artifacts.service';
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_GATEWAY_URL }),
  endpoints: builder => ({
    getMissingArtifacts: builder.query<
      { missingData: any; ignoredData: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingArtifacts();
        return { data: response };
      },
    }),
    getMissingDynamicCalls: builder.query<
      { missingData: any; ignoredData: any; programList: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingDynamicCalls();
        return { data: response };
      },
    }),
    getMissingSymbols: builder.query<
      { missingData: any; ignoredData: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingSymbols();
        return { data: response };
      },
    }),
    getMissingPrograms: builder.query<
      { missingData: any; ignoredData: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingPrograms();
        return { data: response };
      },
    }),
    getMissingProcs: builder.query<
      { missingData: any; ignoredData: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingProcs();
        return { data: response };
      },
    }),
    getMissingControlCards: builder.query<
      { missingData: any; ignoredData: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingControlCards();
        return { data: response };
      },
    }),
    getMissingMetaData: builder.query<
      { missingData: any; ignoredData: any },
      void
    >({
      queryFn: async () => {
        const response = await MissingPageService.getMissingMetadata();
        return { data: response };
      },
    }),
  }),
});

export const {
  useGetMissingArtifactsQuery,
  useLazyGetMissingArtifactsQuery,
  useGetMissingDynamicCallsQuery,
  useGetMissingSymbolsQuery,
  useGetMissingProcsQuery,
  useGetMissingProgramsQuery,
  useGetMissingControlCardsQuery,
  useGetMissingMetaDataQuery,
} = apiSlice;
