import { useSelector } from "react-redux";
import { useMemo } from "react";
import SmartDocTable from "./table";
import { RootState } from "../../../redux/store";
import { helpTexts } from "../../../helpTexts";

const SmartDoc = () => {
    const reportData = useSelector(
        (state: RootState) => state.appGlobal.reportData,
    );
    function sort(list: any[] | undefined, sortFunc: any) {
        [...(list || [])]?.sort(sortFunc);
        return list;
    }
    const data = useMemo(() => {
        const programNames = new Set<string>();
        reportData?.data?.listingReport?.programWiseReport.map(item => (
            programNames.add(item.programName)
        ))
        const programData = Array.from(programNames)
            .map(id => ({ name: id }))
            .sort((a, b) => a.name.localeCompare(b.name));
        return {
            programData
        }
    }, [reportData])

    const helpText = helpTexts[0].SMART_DOC;

    const programData = data?.programData || [];
    return (
        <div className="bg-white rounded-lg shadow-md p-6 mx-auto min-h-[20rem] w-full mt-0">
            <div className="flex flex-col gap-4">
                <SmartDocTable
                    title="SmartDoc"
                    description="Generate documentation for your Cobol Programs"
                    helpText={helpText}
                    tables={[
                        {
                            title: 'Programs',
                            rows: programData,
                            availableProfiles: [
                                data.programData
                            ],
                            helpTexts: helpText
                        }
                    ]}
                />
            </div>
        </div>
    )
}
export default SmartDoc;