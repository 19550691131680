import { EAppIntent } from './redux/app-global/app-global.types';

export const helpTexts = [
  {
    [EAppIntent.EXTRACT_ARTIFACT]: [
      {
        id: 1,
        description:
          'Enter the dataset information for the JCLs to be extracted from the mainframe. After entering the dataset information, click the "+" option to add it. To remove any dataset, click the "-" option next to the corresponding entry.',
      },
      {
        id: 2,
        description:
          'Enter the dataset information for the JCL Procedure (PROC) to be extracted from the mainframe. After entering the dataset information, click the "+" option to add it. To remove any dataset, click the "-" option next to the corresponding entry.',
      },
      {
        id: 3,
        description:
          'Enter the dataset information for the JCL Control Cards to be extracted from the mainframe. After entering the dataset information, click the "+" option to add it. To remove any dataset, click the "-" option next to the corresponding entry.',
      },
      {
        id: 4,
        description:
          'Enter the dataset information for the Compiler Listings to be extracted from the mainframe. After entering the dataset information, click the "+" option to add it. To remove any dataset, click the "-" option next to the corresponding entry.',
      },
      {
        id: 5,
        description:
          'Enter the dataset information for the Metadata to be extracted from the mainframe. After entering the dataset information, click the "+" option to add it. To remove any dataset, click the "-" option next to the corresponding entry.',
      },
      {
        id: 6,
        description:
          'Click this option to generate the JCL required to download artifacts from the mainframe. Before downloading, ensure that you have provided the necessary dataset names in each box to create the JCL.',
      },
      {
        id: 7,
        description:
          'Select an option from the dropdown to specify how you want to download the JCL. Choosing "Single JCL for All Artifacts" will generate one JCL file for all artifacts. If you need separate JCLs for each artifact, select the required extraction option from the dropdown.',
      },
      {
        id: 8,
        description:
          'Click this button when you are ready to upload the artifacts that you have extracted from your mainframe. This will initiate the next step in the modernization process, where you can begin working with your uploaded artifacts.',
      },
      {
        id: 9,
        description:
          'Enter the dataset information for the ISPF libraries to be extracted from the mainframe. To identify your mainframe ISPF libraries, please follow the steps provided in this article ',
      },
      {
        id: 10,
        description:
          'After entering the dataset information, click the "+" option to add it. To remove any dataset, click the "-" option next to the corresponding entry.',
      },
    ],
    [EAppIntent.REPORT]: [
      {
        id: 1,
        description:
          'Use this option to view and interact with call chain graphs on the canvas. This feature allows you to visually explore the call chains and their relationships within the application.',
      },
      {
        id: 2,
        description:
          'Click this option to quickly view the steps of the JCL, illustrating how each step calls other programs. This graphical representation helps you understand the flow and interactions within the JCL.',
      },
      {
        id: 3,
        description:
          'Some artifacts are missing to generate the code. Clicking on this button will navigate to the Missing Artifact Workspace, where you can add the missing artifact.',
      },
      {
        id: 4,
        description:
          'To exit the graph view, click on the “Hide Graph” button.',
      },
      {
        id: 5,
        description:
          "Click this button to navigate to the Smart Doc page, where you can access a detailed description of the program's code flow. The Smart Doc provides in-depth insights into the program's structure, logic, and dependencies, helping you understand its functionality and assess its readiness for transformation.",
      },
    ],
    [EAppIntent.MISSING_PROC]: [
      {
        id: 1,
        description:
          'Check this option to ignore all the listed missing PROCs in the current transformation process. You can also select individual rows to ignore specific PROCs.',
      },
      {
        id: 2,
        description:
          'This column lists the names of the missing PROCs that are required for the transformation process. Each PROC represents a procedure needed by the JCL or another PROC.',
      },
      {
        id: 3,
        description:
          'This column indicates the specific JCL or PROC that depends on the listed PROC. It helps you identify which procedures are essential for the correct execution of the JCL or PROC.',
      },
      {
        id: 4,
        description:
          'Use this option to upload the missing PROC directly from your local system. Upload the corresponding PROC file to resolve the missing information for each listed item.',
      },
      {
        id: 5,
        description:
          'This switch allows you to choose the appropriate option to proceed with resolving the missing PROC information. The "Upload PROCs" option lets you manually upload the missing PROC files from your local system. The "Extract PROCs from Mainframe" option enables you to enter the dataset information to initiate the extraction process of the missing PROC from mainframe.',
      },
      {
        id: 6,
        description:
          'This option allows you to efficiently manage and upload several PROCs at once. Use this option to upload the missing PROCs in a compressed format, such as a ZIP file or XMI.',
      },
      {
        id: 7,
        description:
          'This option allows you to generate the JCL needed to extract the missing PROC from the mainframe. The system will generate the JCL based on the provided dataset names.',
      },
    ],
    [EAppIntent.MISSING_CONTROLCARD]: [
      {
        id: 1,
        description:
          'Check this option to ignore all the listed missing Control Cards in the current transformation process. You can also select individual rows to ignore specific Control Cards.',
      },
      {
        id: 2,
        description:
          'This column lists the names of the missing Control Cards that are required for the transformation process. Each Control Card represents a set of instructions or parameters needed by the JCL or another Control Card.',
      },
      {
        id: 3,
        description:
          'This column indicates the specific JCL or PROC that depends on the listed Control Card. It helps you identify which Control Cards are essential for the correct execution of the JCL or PROC.',
      },
      {
        id: 4,
        description:
          'Use this option to upload the missing Control Card directly from your local system. Upload the corresponding Control Card file to resolve the missing information for each listed item.',
      },
      {
        id: 5,
        description:
          'This switch allows you to choose the appropriate option to proceed with resolving the missing Control Card information. The "Upload Control Cards" option lets you manually upload the missing Control Card files from your local system. The "Extract Control Cards from Mainframe" option enables you to enter the information names to initiate the extraction process of the missing Control Card from mainframe.',
      },
      {
        id: 6,
        description:
          'This option allows you to efficiently manage and upload several Control Cards at once. Use this option to upload the missing Control Cards in a compressed format, such as a ZIP file or XMI.',
      },
      {
        id: 7,
        description:
          'This option allows you to generate the JCL needed to extract the missing Control Card from the mainframe. The system will generate the JCL based on the provided dataset names.',
      },
    ],
    [EAppIntent.MISSING_PROGRAM]: [
      {
        id: 1,
        description:
          'This dropdown lets you manage the status of each listed program. Select Active to resolve the missing program for the transformation process, Ignore to exclude it, or Assembler to specify that it’s acceptable to use stubs for the missing programs. You can apply these settings either globally for all programs or individually for each program in the respective rows.',
      },
      {
        id: 2,
        description:
          'This column lists the names of the missing Programs that are required for the transformation process. Each Program is an essential component or application needed by the JCL or another Program.',
      },
      {
        id: 3,
        description:
          'This column indicates the specific Program that calls or depends on the listed Program. It helps you identify which Programs are essential for the correct execution of the calling Program.',
      },
      {
        id: 4,
        description:
          'Use this option to upload the missing Program directly from your local system. Upload the corresponding Program file to resolve the missing information for each listed item.',
      },
      {
        id: 5,
        description:
          'This switch allows you to choose the appropriate option to proceed with resolving the missing programs information. The "Upload Programs" option lets you manually upload the missing programs from your local system. The "Extract Programs from Mainframe" option enables you to enter the dataset information to initiate the extraction process of the missing programs.',
      },
      {
        id: 6,
        description:
          'This option allows you to efficiently manage and upload several Programs at once. Use this option to upload the missing Programs in a compressed format, such as a ZIP file or XMI.',
      },
      {
        id: 7,
        description:
          'This option allows you to generate the JCL needed to extract the missing Program from the mainframe. The system will generate the JCL based on the provided dataset names.',
      },
      {
        id: 8,
        description:
          'Use the following table to specify any naming conventions or patterns where an external program name differs from its internal counterpart. This allows the system to correctly identify and map programs that follow specific naming rules.',
      },
      {
        id: 9,
        description:
          'Use this option to download a CSV template that you can fill in with the required name mappings. The template provides the necessary structure for entering external and internal program names or patterns.',
      },
      {
        id: 10,
        description:
          'Use this option to upload the file, once you have filled out the CSV template with the name mappings. The system will use the provided mappings to correctly identify and process the programs.',
      },
      {
        id: 11,
        description:
          'This column allows you to enter the external name or naming pattern of the program. This could be a specific name, or a pattern used to identify a set of programs that serve as aliases for internal programs.',
      },
      {
        id: 12,
        description:
          'This column allows you to enter the internal name or naming pattern of the program that corresponds to the external name. The system will map the external names to these internal program names for accurate processing.',
      },
    ],
    [EAppIntent.RESOLVE_DYNAMIC_CALLS]: {
      programTableData: [
        {
          id: 1,
          description:
            'This dropdown lets you manage the status of each listed program. Select Active to resolve the missing program for the dynamic call, Ignore to exclude it, or Assembler to specify that it’s acceptable to use stubs for the missing programs. You can apply these settings either globally for all programs or individually for each program in the respective rows.',
        },
        {
          id: 2,
          description:
            'This column lists the names of the missing Programs that are required for the transformation process. Each Program is an essential component or application needed by the JCL or another Program.',
        },
        {
          id: 3,
          description:
            'This column indicates the specific Program that calls or depends on the listed Program. It helps you identify which Programs are essential for the correct execution of the calling Program.',
        },
        {
          id: 4,
          description:
            'Use this option to upload the missing Program directly from your local system. Upload the corresponding Program file to resolve the missing information for each listed item.',
        },
        {
          id: 5,
          description:
            'This switch allows you to choose the appropriate option to proceed with resolving the missing programs information. The "Upload Programs" option lets you manually upload the missing programs from your local system. The "Extract Programs from Mainframe" option enables you to enter the dataset names to initiate the extraction process of the missing programs.',
        },
        {
          id: 6,
          description:
            'This option allows you to efficiently manage and upload several Programs at once. Use this option to upload the missing Programs in a compressed format, such as a ZIP file or XMI.',
        },
        {
          id: 7,
          description:
            'This option allows you to generate the JCL needed to extract the missing Program from the mainframe. The system will generate the JCL based on the provided dataset names.',
        },
        {
          id: 8,
          description:
            'Use the following table to specify any naming conventions or patterns where an external program name differs from its internal counterpart. This allows the system to correctly identify and map programs that follow specific naming rules.',
        },
        {
          id: 9,
          description:
            'Use this option to download a CSV template that you can fill in with the required name mappings. The template provides the necessary structure for entering external and internal program names or patterns.',
        },
        {
          id: 10,
          description:
            'Use this option to upload the file, once you have filled out the CSV template with the name mappings. The system will use the provided mappings to correctly identify and process the programs.',
        },
        {
          id: 11,
          description:
            'This column allows you to enter the external name or naming pattern of the program. This could be a specific name, or a pattern used to identify a set of programs that serve as aliases for internal programs.',
        },
        {
          id: 12,
          description:
            'This column allows you to enter the internal name or naming pattern of the program that corresponds to the external name. The system will map the external names to these internal program names for accurate processing.',
        },
      ],
      dynamicResolveTable: [
        {
          id: 1,
          description:
            'This column lists the variables used in dynamic calls within the program. These variables represent the program names or parts of program names that are determined at runtime, and resolving them is necessary for accurate transformation.',
        },
        {
          id: 2,
          description:
            'This column indicates the specific program that uses the dynamic call through the listed Program Variable. It helps you identify which Caller Program is dependent on the resolution of the dynamic call.',
        },
        {
          id: 3,
          description:
            'Please select the correct program name from already uploaded programs to resolve the dynamic calls.',
        },
      ],
    },
    [EAppIntent.TRANSFORMATION_PAGE]: [
      {
        id: 1,
        description:
          "Use this option to create a support ticket if you encounter any issues or need assistance during the transformation phase. CloudFrame's customer support team will reach out to guide you through your request and provide the necessary support.",
      },
      {
        id: 2,
        description:
          'The Transformation Summary Report highlights programs that were successfully transformed and identifies those that encountered challenges. This report serves as an essential tool for assessing the transformation outcomes and informing your future strategic planning. It also offers a comprehensive overview of all support tickets raised during the transformation process.',
      },
      {
        id: 3,
        description:
          'The Transform section is your control center for managing the migration of programs ready for transformation. This section is organized into multiple categories, such as Jobs, Programs, and Driver Programs, based on the artifacts you have provided. Each category lists the respective programs that are prepared for migration. You can choose to initiate the migration process individually for each artifact within these categories or collectively for all artifacts in the section. This flexibility allows you to tailor the transformation process to your specific needs, ensuring that each program is migrated according to your timeline and priorities.',
      },
      {
        id: 4,
        description:
          'The Child Libraries displays child programs that are part of artifacts in other sections. These child programs are already included in the main transformation process. However, if you wish to download the code for a specific child program separately, you can do so using this section. This feature provides flexibility, allowing you to access and manage individual child programs as needed, independent of the main artifact transformations.',
      },
      {
        id: 5,
        description:
          'Initiates the transformation process for all listed jobs in the Transformation Queue. By clicking this button, you can start migrating all artifacts collectively, ensuring the relevant transformation rules to each one.',
      },
      {
        id: 6,
        description:
          'Stops any ongoing transformation processes for all artifacts in the queue. Use this button to stop the migration of all jobs, allowing you to make adjustments or reconfigure settings before proceeding further.',
      },
      {
        id: 7,
        description:
          'Lists the names of the jobs that are ready for transformation. Each job represents a set of tasks or processes within your application that can be migrated.',
      },
      {
        id: 8,
        description:
          'Allows you to choose the specific rules or criteria applied to the job during the transformation process, ensuring that the migration aligns with your application’s requirements.',
      },
      {
        id: 9,
        description:
          "View the call chain for the job using the 'View Callchain' option, which provides a graphical representation of how the job interacts with other programs.",
      },
      {
        id: 10,
        description:
          'Allows you to initiate the transformation of the job. Toggle this option to start the migration process for the specific job, converting it according to the applied transformation rules.',
      },
      {
        id: 11,
        description:
          'Lists the names of the driver programs that are ready for transformation.',
      },
      {
        id: 12,
        description:
          'This column provides a checkbox option for each CICS Driver program. Selecting the checkbox will bundle the relevant program as a service and initiate the download process. If the checkbox is left unselected, the program will not be downloaded as a service.',
      },
      {
        id: 13,
        description:
          'View the call chain for the driver program using the "View Callchain" option, which provides a graphical representation of how the CICS driver program interacts with other programs.',
      },
      {
        id: 14,
        description:
          'Allows you to initiate the migration of the CICS driver program. Toggle this option to start the migration process for the specific CICS driver program.',
      },
      {
        id: 15,
        description:
          'Lists the names of the specific child programs that are ready for transformation.',
      },
      {
        id: 16,
        description:
          'Allows you to choose the specific rules or criteria applied to the child program during the transformation process, ensuring that the migration aligns with your application’s requirements.',
      },
      {
        id: 17,
        description:
          "View the call chain for the specific child program using the 'View Callchain' option, which provides a graphical representation of how the child program interacts with other programs.",
      },
      {
        id: 18,
        description:
          'Allows you to initiate the migration of the child program. Toggle this option to start the migration process for the specific child program, converting it according to the applied transformation rules.',
      },
      {
        id: 19,
        description:
          'Initiates the transformation process for all listed CICS driver programs in the Transformation Queue. By clicking this button, you can start migrating all artifacts collectively.',
      },
      {
        id: 20,
        description:
          'Stops any ongoing transformation processes for all artifacts in the queue. Use this button to stop the migration of all CICS driver programs, allowing you to make adjustments or reconfigure settings before proceeding further.',
      },
      {
        id: 21,
        description:
          'Initiates the transformation process for all listed child programs in the Child Libraries section. By clicking this button, you can start migrating all artifacts collectively, ensuring the relevant transformation rules to each one.',
      },
      {
        id: 22,
        description:
          'Stops any ongoing transformation processes for all artifacts in the Child Libraries section. Use this button to stop the migration of all Child programs, allowing you to make adjustments or reconfigure settings before proceeding further.',
      },
      {
        id: 23,
        description:
          'Lists the names of the CICS driver programs that are ready for transformation.',
      },
    ],
    ['INFORMATION_CENTER']: [
      {
        id: 1,
        description:
          'The Information Center provides valuable insights and resources guiding you through each phase of the process for modernization. This interactive step-by-step wizard helps to ensure a smooth and efficient transition from legacy systems to modern solutions.',
      },
      {
        id: 2,
        description:
          'Click this button to access the Missing Artifact Workspace, where you can resolve any missing artifacts identified during the Readiness phase. Please address all missing artifacts before proceeding to the Transform phase. This ensures a smooth and complete transformation process.',
      },
    ],
    ['CALL_CHAIN']: [
      {
        id: 1,
        description:
          'Select the desired program or JCL from the dropdown to view its call chain in the canvas. This allows you to focus on specific artifacts and analyze their relationships within the system.',
      },
      {
        id: 2,
        description:
          "This represents the elements displayed in the canvas, offering an easy reference to understand the different components of your program's structure through color codes.",
      },
      {
        id: 3,
        description:
          "Click this button to automatically adjust the view, ensuring the entire graph fits within the canvas. This helps you get a comprehensive view of the selected artifact's call chain.",
      },
      // {
      //     id:4,
      //     description:"Click this button to exit the interactive canvas view and navigate back to the overall assessment report page."
      // },
    ],
    [EAppIntent.MISSING_SYMBOLS]: [
      {
        id: 1,
        description:
          'This column lists the missing JCL symbols that need to be defined for the transformation process.',
      },
      {
        id: 2,
        description:
          'This column indicates the specific JCL or PROC that requires the corresponding symbol. It helps you identify where each symbol is needed in the transformation process.',
      },
      {
        id: 3,
        description:
          'Please enter the value for the corresponding missing symbol in this field. The value you provide will be used to replace the missing symbol in the corresponding JCL or PROC.',
      },
      {
        id: 4,
        description:
          'Use this option to upload the missing Program directly from your local system. Upload the corresponding Program file to resolve the missing information for each listed item.',
      },
      {
        id: 5,
        description:
          'This switch allows you to choose the appropriate option to proceed with resolving the missing programs information. The "Upload Programs" option lets you manually upload the missing programs from your local system. The "Extract Control Programs from Mainframe" option enables you to enter the dataset names to initiate the extraction process of the missing programs.',
      },
      {
        id: 6,
        description:
          'This option allows you to efficiently manage and upload several Programs at once. Use this option to upload the missing Programs in a compressed format, such as a ZIP file or XMI.',
      },
      {
        id: 7,
        description:
          'This option allows you to generate the JCL needed to extract the missing Program from the mainframe. The system will generate the JCL based on the provided dataset names.',
      },
      {
        id: 8,
        description:
          'Click this option to download a CSV template. The template allows you to input symbol values offline. Once completed, you can upload the CSV to automatically populate the symbol values on the page.',
      },
      {
        id: 9,
        description:
          'Use this option to upload a completed CSV file with the symbol values. The uploaded file will populate the symbol values on the page, saving you time from entering them manually.',
      },
      {
        id: 10,
        description:
          'Use this option to upload the file, once you have filled out the CSV template with the name mappings. The system will use the provided mappings to correctly identify and process the programs.',
      },
      {
        id: 11,
        description:
          'This column allows you to enter the external name or naming pattern of the program. This could be a specific name, or a pattern used to identify a set of programs that serve as aliases for internal programs.',
      },
      {
        id: 12,
        description:
          'This column allows you to enter the internal name or naming pattern of the program that corresponds to the external name. The system will map the external names to these internal program names for accurate processing.',
      },
    ],
    [EAppIntent.ARTIFACT_UPLOAD]: [
      {
        id: 1,
        description:
          'Clicking on this button will validate the uploaded files. Please click this button after uploading the necessary artifacts for migration.',
      },
      {
        id: 2,
        description:
          'Click this button once you have successfully uploaded the artifacts extracted from your mainframe. This will move you forward to the next step in the modernization process.',
      },
    ],
    [EAppIntent.MISSING_METADATA]: [
      {
        id: 1,
        description:
          'Check this option to ignore all the listed missing Metadata in the current transformation process. You can also select individual rows to ignore specific Metadata.',
      },
      {
        id: 2,
        description:
          'This column lists the names of the missing Metadata that are required for the transformation process. Each Metadata entry represents a set of data definitions or parameters needed by the JCL or another Metadata item.',
      },
      {
        id: 3,
        description:
          'This column indicates the specific JCL or PROC that depends on the listed Metadata. It helps you identify which Metadata items are essential for the correct execution of the JCL or PROC.',
      },
      {
        id: 4,
        description:
          'This column specifies the length of each record in the dataset. Enter the appropriate value to ensure the correct processing of data during transformation.',
      },
      {
        id: 5,
        description:
          'This column indicates the format of the records in the dataset (e.g., Fixed, Variable). Select the correct format to match your dataset’s structure for accurate transformation.',
      },
      {
        id: 6,
        description:
          'This option allows you to efficiently manage and upload several Metadata at once. Use this option to upload the missing Programs in a compressed format, such as a ZIP file or XMI.',
      },
      {
        id: 7,
        description:
          'This represents the name of the data cluster associated with the missing artifact. Ensure that the correct cluster name is provided to accurately link the metadata during the transformation process.',
      },
      {
        id: 8,
        description:
          'This option allows you to generate the JCL needed to extract the missing Metadata from the mainframe. The system will generate the JCL based on the provided dataset names and ISPF libraries.',
      },
      {
        id: 9,
        description:
          'This switch allows you to choose the appropriate option to proceed with resolving the missing Metadata information. The "Upload Metadata" option lets you manually upload the missing Metadata files from your local system. The "Extract Metadata from Mainframe" option enables you to enter the dataset names and ISPF data to initiate the extraction process of the missing Metadata from mainframe.',
      },
      {
        id: 10,
        description:
          'To identify your mainframe ISPF libraries, please follow the steps provided in this article',
      },
    ],
    [EAppIntent.SMART_DOC] : [
      {
        id:1,
        description: 'The SmartDoc page displays a list of your COBOL programs, along with options to visualize call chains and SmartDoc for each program. This help you gain a clear understanding of the structure and flow of your COBOL programs.'
      },
      {
        id:2,
        description: 'This column lists the names of the COBOL programs that are part of the current transformation project. You can search for any program using the search bar to quickly access its documentation or call chain details.'
      },
      {
        id:3,
        description: "This column provides the option to view the call chain graph for each program. The View Callchain button allows you to visualize the program's flow and its interactions with other programs or jobs, helping you understand dependencies and program structure."
      },
      {
        id:4,
        description: "This column contains the Show Report button, which opens the automatically generated documentation for each program. The document includes details about the program's structure, key operations, and any control flow, providing key insights of your project's code."
      },
      {
        id:5,
        description: "AI Enhanced Documentation cannot be generated because there are no required LLM Parameters provided by the admin."
      },
    ]
  },
];
