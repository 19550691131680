import JSZip from 'jszip';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Viz from 'viz.js';
import { Module, render } from 'viz.js/full.render.js';
import { SESSION_HEADER } from '../constants/app.constant';
import { appTheme } from '../styles/theme';
import { addColorLegend, getCardColors } from './call-chain.service';
import { setMissingPrograms, setMissingUtilities } from '../redux/app-global';
import { SharedService } from '../services/shared-service';
import { RootState } from '../redux/store';
import localforage from 'localforage';
import { externalConfig } from '../utils/misc.utils';

const appColors = appTheme.colors;

const unsupportedModules: string[] = ['DBNTRY', 'SQLADR', 'SQLBEX', 'ISPLINK'];

const useCallChainUtils = () => {
  const [jclArtifacts, setJCLArtifacts] = useState<any[]>([]);
  const [jclArtifactIds, setJCLArtifactIds] = useState<string[]>([]);
  const [metadata, setMetadata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [usedColors, setUsedColors] = useState<string[]>([]);
  const [selectedProgramId, setSelectedProgramId] = useState('');
  const scanCompleted = useSelector(
    (state: RootState) => state.appGlobal.scanCompleted,
  );

  const [programs, setPrograms] = useState<any>([]);
  const [programIds, setProgramIds] = useState<string[]>([]);
  const [svgString, setSvgString] = useState('');
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const isProgramSelected = programIds.includes(selectedProgramId);
  const currentIndex = (
    isProgramSelected ? programIds : jclArtifactIds
  ).findIndex(item => item === selectedProgramId);

  // console.log('jclArtifacts', jclArtifacts);
  // console.log('jclArtifactIds', jclArtifactIds);
  // console.log('metadata', metadata);
  // console.log('programs', programs);
  // console.log('programIds', programIds);

  useEffect(() => {
    (async () => {
      setError(false);
      let callChainData: string | null = null;
      try {
        callChainData = await localforage.getItem<string>('callChainArtifacts');
      } catch (err) {
        console.log(err);
      }
      if (scanCompleted && !callChainData) {
        handleFetchArtifactZip();
      } else if (scanCompleted && callChainData) {
        const parsedData = JSON.parse(callChainData);
        setMetadata(parsedData.artifactsMetadata);
        setJCLArtifacts(parsedData.storedJclArtifacts || []);
        setJCLArtifactIds(parsedData.storedJclArtifactIds || []);
        setPrograms(parsedData.storedPrograms || []);
        setProgramIds(parsedData.storedProgramIds || []);
      }
    })();
  }, [scanCompleted]);

  useEffect(() => {
    if (selectedProgramId) {
      getSvgString(selectedProgramId);
    } else {
      const sortedJCLIds = [...jclArtifactIds]?.sort((a, b) =>
        a.localeCompare(b),
      );
      const sortedProgramIds = [...programIds]?.sort((a, b) =>
        a.localeCompare(b),
      );
      const selectedPgm = sortedJCLIds[0] || sortedProgramIds[0];
      setSelectedProgramId(selectedPgm || '');
      getSvgString(selectedPgm || '');
    }
  }, [selectedProgramId, programIds, jclArtifactIds]);

  function filterUnconnectedNodes(nodes: any[], links: any[], rootId: string) {
    const connectedNodes = new Set<string>();
    const queue: string[] = [rootId];

    // Perform BFS (Breadth-First Search) starting from the root node
    while (queue.length > 0) {
      const currentNodeId = queue.shift() as string;
      connectedNodes.add(currentNodeId);

      links.forEach(link => {
        if (link.source === currentNodeId && !connectedNodes.has(link.target)) {
          connectedNodes.add(link.target);
          queue.push(link.target);
        }
      });
    }

    // Filter unconnected nodes and their links
    const filteredNodes = nodes.filter(node => connectedNodes.has(node.id));
    const filteredLinks = links.filter(
      link =>
        connectedNodes.has(link.source) && connectedNodes.has(link.target),
    );

    return { nodes: filteredNodes, links: filteredLinks };
  }

  function convertProgramToNodesAndLinks(data: any) {
    const nodes: any[] = [];
    const links: any[] = [];
    let ids: string[] = [];
    const uniqueLinks = new Set<string>();
    data.forEach((item: any) => {
      item = JSON.parse(item);
      if (item.id) {
        item.id = item.id.split('__')[0];
      }
      if (item.MQ_MODULES && Array.isArray(item.MQ_MODULES)) {
        item.MQ_MODULES.forEach((module: string) => {
          const mqlink = { id: module, type: 'program' };
          item.links = item.links || [];
          item.links.push(mqlink);
        });
      }
      const node = { id: item.id, ...item };
      nodes.push(node);
      ids.push(item.id);
      if (item.links) {
        item.links?.forEach((link: { id: string; isDynamic?: boolean }) => {
          const isDynamic = link.hasOwnProperty('isDynamic')
            ? link.isDynamic
            : false;
          const newLink = { source: item.id, target: link.id, isDynamic };
          const linkKey = `${newLink.source}-${newLink.target}`;

          if (!uniqueLinks.has(linkKey)) {
            uniqueLinks.add(linkKey);
            links.push(newLink);
          }
        });
      }
    });
    links.forEach(link => {
      const { source, target, isDynamic } = link;
      if (!nodes.some(n => n.id === source)) {
        nodes.push({ id: source, ProgramName: source, isDynamic: isDynamic });
      }
      if (!nodes.some(n => n.id === target)) {
        nodes.push({ id: target, ProgramName: target, isDynamic: isDynamic });
      }
    });
    const result = ids?.map(x => {
      return filterUnconnectedNodes(
        nodes.map(y => (x === y.id ? { ...y, isDriverProgram: true } : y)),
        links,
        x,
      );
    });

    ids = Array.from(new Set(ids));
    setPrograms(result || []);
    setProgramIds(ids || []);
    return { result, ids };
  }

  async function fetchCallChainArtifactZipData() {
    const sessionId = sessionStorage.getItem(SESSION_HEADER);
    if (!sessionId) {
      throw new Error(`Session id is empty !!!`);
    }
    const response = await fetch(
      `${externalConfig.REACT_APP_API_URL}/scan/result/call-chain`,
      {
        method: 'POST',
        headers: {
          'X-CF-SESSIONID': sessionId,
        },
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.arrayBuffer();
    return data;
  }

  async function handleFetchArtifactZip() {
    try {
      setLoading(true);
      const data = await fetchCallChainArtifactZipData();

      const zipContents = await JSZip.loadAsync(data);

      // test
      // const blob = new Blob([data], { type: 'application/zip' });
      // const url = URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = 'callchain.zip';
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
      // URL.revokeObjectURL(url);

      const jclJsonFiles: Promise<string>[] = [];
      const programJsonFiles: Promise<string>[] = [];
      const extractedIds: string[] = [];

      let schemaMetadata: string | null = null;
      const fileNames = Object.keys(zipContents.files);
      for (let i = 0; i < fileNames.length; i++) {
        const file = zipContents.files[fileNames[i]];
        if (file.name?.endsWith('.json') || file.name?.endsWith('.JSON')) {
          let fileId = file.name.split('.')[0];
          fileId = fileId.replace(/\\/g, '/');
          const [programView, foldername, fileName] = fileId.split('/');
          if (jclArtifactIds.includes(fileId)) {
            throw new Error(`File "${fileId}.json" already exists.`);
          }

          if (foldername === 'metadata') {
            schemaMetadata = await file.async('text');
          } else {
            if (foldername === 'jcl') {
              extractedIds.push(fileName?.toUpperCase());
              jclJsonFiles.push(file.async('text'));
            } else if (foldername === 'object' || foldername === 'program') {
              programJsonFiles.push(file.async('text'));
            }
          }
        }
      }

      if (!schemaMetadata) {
        throw new Error(`metadata file doesn't exist !!!`);
      }
      const parsedMetadata = JSON.parse((await schemaMetadata) || '{}');
      setMetadata(parsedMetadata);
      const jclJsonContents = await Promise.all(jclJsonFiles);
      const programJsonContent = await Promise.all(programJsonFiles);
      const parsedPrograms: any[] = [];

      jclJsonContents.forEach(content => {
        const parsedJson = JSON.parse(content);
        parsedPrograms.push([
          parsedJson,
          {
            programs: programJsonContent.map(programContent =>
              JSON.parse(programContent),
            ),
          },
        ]);
      });
      const { result, ids: pgmIds } =
        convertProgramToNodesAndLinks(programJsonContent);
      setJCLArtifacts([...jclArtifacts, ...parsedPrograms]);
      setJCLArtifactIds([...jclArtifactIds, ...extractedIds]);

      try {
        await localforage.setItem(
          'callChainArtifacts',
          JSON.stringify({
            storedJclArtifacts: [...jclArtifacts, ...parsedPrograms],
            storedJclArtifactIds: [...jclArtifactIds, ...extractedIds],
            artifactsMetadata: parsedMetadata,
            storedPrograms: result,
            storedProgramIds: pgmIds,
          }),
        );
      } catch (err) {
        console.log(err);
      }
    } catch (error) {
      console.error('Error processing zip:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  function rgbaToHex(rgba: string) {
    const [r, g, b, a] = rgba.match(/\d+/g) as any;
    const hex = (x: any) => ('0' + parseInt(x).toString(16)).slice(-2);

    return `#${hex(r)}${hex(g)}${hex(b)}`;
  }

  function convertToExecutionFlowGraph(jsonData: any) {
    let nodes: any = [];
    const links: any = [];
    const uniqueLinks = new Set<string>();
    let jobName = '';
    jsonData.forEach(item => {
      if (item.type === 'job') {        
        if (item.id) {
          item.id = item.id.split('__')[0];
        }
        nodes.push({
          id: item.id,
          nodeType: 'job',
          size: { width: 5000, height: 2000 },
        });
        jobName = item.id;
        item.links.forEach(step => {
          if (step.id) {
            step.id = step.id.split('__')[0];
          }
          nodes.push({
            id: step.id,
            nodeType: 'step',
            parm: step.parm,
            sequence: step.sequence,
            datasets: step.datasets,
            condition: step.condition,
            size: { width: 4400, height: 2000 },
          });
          let linkKey = `${item.id}-${step.id}`;
          if (!uniqueLinks.has(linkKey)) {
            uniqueLinks.add(linkKey);
            links.push({
              source: item.id,
              target: step.id,
            });
          }

          if (step.links) {
            if (step.links.id) {
              step.links.id = step.links.id.split('__')[0];
            }
            nodes.push({
              id: step.links.id,
              nodeType: 'program',
              isDriverProgram: true,
              isCalledFromJCL: true,
            });
            linkKey = `${step.id}-${step.links.id}`;
            if (!uniqueLinks.has(linkKey)) {
              uniqueLinks.add(linkKey);
              links.push({
                source: step.id,
                target: step.links.id,
              });
            }
          }
        });
      } else {
        item.programs.forEach(program => {
          const { ProgramName, links: calls, ...rest } = program;
          if (program.id) {
            program.id = program.id.split('__')[0];
          }
          const progNode = nodes.find((n: any) => n.id === program.id);
          if (
            program.id.toUpperCase().startsWith('DSN') ||
            program.id.toUpperCase().startsWith('DFH')
          ) {
            return;
          }

          const nodeData = {            
            ...rest,
            id: program.id,
          };
          if (progNode) {
            nodes = nodes.map((n: any) =>
              n.id === program.id ? { ...progNode, ...nodeData } : n,
            );
            
          } else {
            nodes.push(nodeData);
          }

          calls?.forEach(call => {
            if (call.id) {
              call.id = call.id.split('__')[0];
            }
            const calledProgram = nodes.find((n: any) => n.id === call.id);

            if (
              call.id.toUpperCase().startsWith('DSN') ||
              call.id.toUpperCase().startsWith('DFH')
            ) {
              return;
            }

            if (!calledProgram) {
              nodes.push({
                id: call.id,
                nodeType: 'program',
              });
            }
            const isDynamic = call.hasOwnProperty('isDynamic') ? call.isDynamic : false;
            const newLink = { source: program.id, target: call.id, isDynamic };
            const linkKey = `${newLink.source}-${newLink.target}`;
            if (!uniqueLinks.has(linkKey)) {
              uniqueLinks.add(linkKey);
              links.push(newLink);
            }
          });
        });
      }
    });

    const { nodes: filteredNodes, links: filteredLinks } =
      filterUnconnectedNodes(nodes, links, jobName);

    let unsupportedModulesFound = nodes.some((node: { id: string }) =>
      unsupportedModules.includes(node.id.toUpperCase()),
    );

    filteredNodes.sort((a, b) => {
      if (a.nodeType === 'step' && b.nodeType === 'step') {
        return a.sequence - b.sequence;
      }
      return 0;
    });
    return {
      unsupported: unsupportedModulesFound,
      nodes: filteredNodes,
      links: filteredLinks,
    };
  }

  function convertToDot(jsonData: any, selected: string, legend: any) {
    const recursivePgrms: any = {};
    const usedColors = new Set<string>();
    jsonData?.links?.forEach((link: any) => {
      if (link?.source === link?.target) {
        recursivePgrms[link.target] = true;
      }
    });

    let dotContent = 'digraph MyGraph {\n ';

    dotContent += `node [ shape="plaintext" style="filled, rounded" fontname="Lato" margin=0.2 ordering="out"]
      edge [ fontname="Lato" color="#2B303A" ]\n`;
    dotContent += `splines=true;\n`;
    jsonData.nodes?.forEach((node: any) => {
      const isDynamic = !!jsonData.links?.some((link: any) => {
        if (link.isDynamic && link.target === node.id) return true;
        return link.label === 'Dynamic call' && link.target === node.id;
      });
      const { backgroundColor, textColor } =
        !!legend && Object.keys(legend || '{}').length > 0
          ? {
              backgroundColor:
                legend[node.legend]?.nodeColor || appColors.grey20,
              textColor: legend[node.legend]?.textColor || 'black',
            }
          : getCardColors(
              node.id,
              selected,
              !!recursivePgrms[node.id],
              isDynamic,
              node.missingProgram,
              node.isDriverProgram,
              node.isSupported,
              node.isCalledFromJCL,
              node.nodeType,
            );

      if (node.nodeType !== 'job' && node.nodeType !== 'step') {
        usedColors.add(backgroundColor);
      }

      if (node.id === selected && node.nodeType === 'job') {
        dotContent += `  ${node.id
          .replaceAll('-', '_')
          .replaceAll(' ', '_')
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll('#', '')
          .replaceAll('$', '')
          .replaceAll('@', '')
          .replace(/^(\d)/, 'Q$1')} [label=<
          <TABLE BORDER="0" CELLBORDER="0" CELLSPACING="0">
              <TR>
                  <TD ALIGN="CENTER">${node.id}</TD>
              </TR>
              <TR>
                  <TD ALIGN="CENTER"><FONT POINT-SIZE="10">Job</FONT></TD>
              </TR>
          </TABLE>
      > fillcolor="${rgbaToHex(appColors.orangeSmooth)}" fontcolor="black"];\n`;
      } else if (node.nodeType === 'step') {
        dotContent += `  ${node.id
          .replaceAll('-', '_')
          .replaceAll(' ', '_')
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll('#', '')
          .replaceAll('$', '')
          .replaceAll('@', '')
          .replace(/^(\d)/, 'Q$1')} [label=<
          <TABLE BORDER="0" CELLBORDER="0" CELLSPACING="0">
              <TR>
                  <TD ALIGN="CENTER">${node.id}</TD>
              </TR>
              <TR>
                  <TD ALIGN="CENTER"><FONT POINT-SIZE="10">Step</FONT></TD>
              </TR>
          </TABLE>
      > fillcolor="${rgbaToHex(appColors.blueSmooth)}" fontcolor="black"];\n`;
      } else {
        dotContent += `  ${node.id
          .replaceAll('-', '_')
          .replaceAll(' ', '_')
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll('#', '')
          .replaceAll('$', '')
          .replaceAll('@', '')
          .replace(/^(\d)/, 'Q$1')} [label="${node.id.replaceAll(
          '#',
          '',
        )}" fillcolor="${
          backgroundColor.startsWith('rgb')
            ? rgbaToHex(backgroundColor)
            : backgroundColor
        }" fontcolor="${
          textColor.startsWith('rgb') ? rgbaToHex(textColor) : textColor
        }"];\n`;
      }
    });
    jsonData.links.forEach((link: any) => {
      dotContent += `  ${link.source
        .replaceAll('-', '_')
        .replaceAll(' ', '_')
        .replaceAll('(', '')
        .replaceAll('#', '')
        .replaceAll('$', '')
        .replaceAll('@', '')
        .replaceAll(')', '')
        .replace(/^(\d)/, 'Q$1')} -> ${link.target
        .replaceAll('-', '_')
        .replaceAll(' ', '_')
        .replaceAll('(', '')
        .replaceAll('#', '')
        .replaceAll('$', '')
        .replaceAll('@', '')
        .replaceAll(')', '')
        .replace(/^(\d)/, 'Q$1')};\n`;
    });

    dotContent += '}';
    const usedColorsArray = Array.from(usedColors);
    return { dotContent, usedColorsArray };
  }

  async function getSvgString(selected: string, dontUseState = false) {
    const isProgramSelected = programIds.includes(selected);
    const curIndex = (
      isProgramSelected ? programIds : jclArtifactIds
    ).findIndex(item => item === selected);

    const viz = new Viz({ Module, render });
    const graphData =
      currentIndex === -1
        ? { nodes: [], links: [] }
        : isProgramSelected
          ? programs[curIndex]
          : convertToExecutionFlowGraph(jclArtifacts[curIndex]);
    let { dotContent, usedColorsArray } = convertToDot(
      graphData,
      selected,
      (metadata as any)?.legend,
    );

    const svgString = await viz.renderString(dotContent, { format: 'svg' });

    const modifiedString = addColorLegend(
      svgString,
      usedColorsArray,
      (metadata as any)?.legend,
    ) as string;
    if (dontUseState) {
      return modifiedString;
    }
    setSvgString(svgString);
    setUsedColors(usedColorsArray);
  }

  const handleClear = async () => {
    setJCLArtifacts([]);
    setJCLArtifactIds([]);
    setPrograms([]);
    setProgramIds([]);
    await localforage.removeItem('callChainArtifacts');
  };

  const getAllSvgStrings = async () => {
    const programToSvgMap: { [key: string]: string } = {};
    for (let i = 0; i < jclArtifactIds.length; i++) {
      const jclId = jclArtifactIds[i];
      try {
        programToSvgMap[jclId] = (await getSvgString(jclId, true)) as string;
      } catch (error) {
        console.log(error);
      }
    }

    for (let i = 0; i < programIds.length; i++) {
      const programId = programIds[i];
      programToSvgMap[programId] = (await getSvgString(
        programId,
        true,
      )) as string;
    }
    dispatch(setMissingPrograms(Array.from(SharedService.missingPrograms)));
    dispatch(setMissingUtilities(Array.from(SharedService.missingUtilities)));
    SharedService.missingPrograms.clear();
    SharedService.missingUtilities.clear();
    return programToSvgMap;
  };

  return {
    jclArtifacts,
    jclArtifactIds,
    programs,
    programIds,
    metadata,
    svgString,
    usedColors,
    error,
    handleFetchArtifactZip,
    handleClear,
    loading,
    selectedProgramId,
    setSelectedProgramId,
    getAllSvgStrings,
  };
};

export { useCallChainUtils };
