import { EAppIntent } from '../redux/app-global/app-global.types';

export class SharedService {
  static handleOption = null;
  static isOnPrem = true;
  static genericModalMessage = '';
  static reportAPICount = 0; //Used for keeping count of number of times reportAPI is triggered
  static documentationHtmlContent = '';
  static documentationProgramName = '';
  static genericAlertModal = {
    title: '',
    description: '',
  };
  static missingPrograms = new Set<string>();
  static missingUtilities = new Set<string>();
  static selectedProgram: { name: string; type: 'jcl' | 'program' } = {
    name: '',
    type: 'jcl',
  };
  static nextMissingPage: EAppIntent | '' = '';
}
