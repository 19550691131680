import axios from 'axios';
import localforage from 'localforage';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVGLoader from '../../assets/images/svg/loader.icon';
import SVGTrash from '../../assets/images/svg/trash.icon';
import { VALID_FILE_TYPES } from '../../constants/app.constant';
import {
  setActiveModal,
  setCurrentIntent,
  setEmailSent,
  setIsCallChainLoaded,
  setNextMissingPageTest,
  setReportData,
  setScanCompleted,
  setScanProgress,
  setTransformationProgress,
  setTransformationStatus,
  setUploadedArtifacts,
  setUploadedMissingArtifacts,
} from '../../redux/app-global';
import { EAppIntent, EAppModal } from '../../redux/app-global/app-global.types';
import { RootState } from '../../redux/store';
import { SharedService } from '../../services/shared-service';
import { appTheme } from '../../styles/theme';
import {
  externalConfig,
  scrollInformationCenterToEnd,
} from '../../utils/misc.utils';
import RadialProgress from '../radial-progress/radial-progress';
import UploadButton from '../upload-button/upload-button';
import ValidateButton from '../validate-button/validate-button';
import FileSummarySection from './file-summary-section/file-summary-section';
import { UploadFile } from './upload-list.types';
import { helpTexts } from '../../helpTexts';
import HelpTextCallout from '../help-text-callout/help-text-callout';
import { startScan } from './upload-list.service';

function UploadList() {
  const dispatch = useDispatch();
  const uploadedArtifacts = useSelector(
    (state: RootState) => state.appGlobal.uploadedArtifacts,
  );
  const missingArtifacts = useSelector(
    (state: RootState) => state.appGlobal.uploadedMissingArtifacts,
  );
  const license = useSelector((state: RootState) => state.appGlobal.license);
  const [files, setFiles] = useState<Record<string, UploadFile>>({});
  const [uploadProgress] = useState<{
    [name: string]: { uploadProgress: number; error: string };
  }>({});
  const [progress, setProgress] = useState<number>(0);
  const [uploadingFile, setUploadingFile] = useState<null | string>('');
  const [missingFiles, setMissingFiles] = useState<string[]>([]);
  const [dependentFiles, setDependentFiles] = useState<string[]>([]);
  const [validatingFiles, setValidatingFiles] = useState<boolean>(false);
  const [zipFileDetailsVisible, setZipFileDetailsVisible] = useState<
    Record<string, boolean>
  >({});
  const [zipFileStatus, setZipFileStatus] = useState<Record<string, any>>({});
  const [fileStatus, setFileStatus] = useState<Record<string, any>>({});
  const pageHelpText = helpTexts[0].ARTIFACT_UPLOAD;
  useEffect(() => {
    const combinedArtifacts = {
      ...uploadedArtifacts?.artifacts,
      ...missingArtifacts?.artifacts,
    };
    const zipFileStatus = {
      ...uploadedArtifacts?.zipFileStatus,
      ...missingArtifacts?.zipFileStatus,
    };
    const fileStatus = {
      ...uploadedArtifacts?.fileStatus,
      ...missingArtifacts?.fileStatus,
    };
    setFiles(combinedArtifacts || {});
    setMissingFiles(uploadedArtifacts?.missingFiles || []);
    setDependentFiles(uploadedArtifacts?.dependentFiles || []);
    setZipFileStatus(zipFileStatus || {});
    setFileStatus(fileStatus || {});
  }, []);
  const formatFileSize = (size: number) => {
    return size < 1024
      ? `${size} B`
      : size < 1048576
        ? `${(size / 1024).toFixed(2)} KB`
        : size < 1073741824
          ? `${(size / 1048576).toFixed(2)} MB`
          : `${(size / 1073741824).toFixed(2)} GB`;
  };

  const getFileType = (file: any) => {
    return file.name.includes('.')
      ? file.name.split('.').pop().toUpperCase()
      : '';
  };

  const getUploadFileInfo = (file: any): UploadFile => {
    const fileInfo: UploadFile = {
      name: file.name,
      size: formatFileSize(file.size),
      type: getFileType(file),
      originalFile: file,
      uploadProgress: 0,
      error: '',
    };
    return fileInfo;
  };

  function extractSerializableFileProperties(
    files: Record<string, UploadFile>,
  ): Record<string, any> {
    const serializableFiles: Record<string, any> = {};

    Object.keys(files).forEach(key => {
      const file = files[key];
      const serializableFile = {
        ...file,
        originalFile: {
          size: file.originalFile.size,
          name: file.originalFile.name,
        },
      };
      serializableFiles[key] = serializableFile;
    });

    return serializableFiles;
  }

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const fileSet = event.target.files;

    if (!fileSet) {
      event.preventDefault();
      event.target.value = '';
      return;
    }

    const newFiles = { ...files };
    const totalSize =
      Array.from(fileSet).reduce(
        (acc: number, file: File) => acc + file.size,
        0,
      ) +
      Object.values(files).reduce(
        (acc, file) => acc + file.originalFile.size,
        0,
      );

    const limit = license?.fileUploadLimit || 100;
    const maxFilesSize = limit * 1024 * 1024;
    if (totalSize > maxFilesSize) {
      event.target.value = '';
      dispatch(setActiveModal(EAppModal.FILE_SIZE_LIMIT_EXCEEDED));
      return;
    }
    if (fileSet && fileSet.length) {
      for (let i = 0; i < fileSet.length; i++) {
        const file = fileSet[i];
        setUploadingFile(file.name);
        try {
          const fileInfo = getUploadFileInfo(file);
          await uploadFile(fileInfo);
          setZipFileStatus(prev => ({
            ...prev,
            [file.name]: { status: 'PENDING' },
          }));
          setFileStatus(prev => ({
            ...prev,
            [file.name]: { status: 'PENDING' },
          }));
          newFiles[file.name] = fileInfo;
          setFiles(newFiles);
          SharedService.reportAPICount = 0;
          setDependentFiles([]);
          setMissingFiles([]);
          dispatch(
            setUploadedArtifacts({
              artifacts: extractSerializableFileProperties(newFiles),
              missingFiles,
              dependentFiles,
              zipFileStatus: {
                ...zipFileStatus,
                [file.name]: { status: 'PENDING' },
              },
              fileStatus: { ...fileStatus, [file.name]: { status: 'PENDING' } },
            }),
          );
        } catch (err) {
          console.log(err);
          dispatch(setActiveModal(EAppModal.FILE_UPLOAD_FAILED));
          setProgress(0);

          setUploadingFile('');
          return;
        } finally {
          setProgress(0);

          setUploadingFile('');
        }

        setTimeout(() => {
          scrollInformationCenterToEnd();
        }, 0);
      }
    }
    event.target.value = '';
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const fileSet = event.dataTransfer.files;
    if (fileSet && fileSet.length > 0) {
      addFilesToList(fileSet);
    }
  };
  const validateFile = async (Tfiles: File[]) => {
    setProgress(100);
    setValidatingFiles(true);
    const url = `${externalConfig.REACT_APP_API_URL}/file/validate`;
    return await axios
      .post(url, null, { timeout: 900000 }) // 15 minutes
      .then((res: any) => {
        setValidatingFiles(false);
        const newZipFileStatus = {};
        const newFileStatus = {};
        Tfiles.forEach(child => {
          if (
            res.data.data[child.name]?.type === 'zip' ||
            res.data.data[child.name]?.type === 'XMI' ||
            res.data.data[child.name]?.type === 'xmi'
          ) {
            newZipFileStatus[child.name] = res.data.data[child.name];
            newFileStatus[child.name] = {
              ...fileStatus[child.name],
              status:
                res.data.data[child.name]?.status ||
                fileStatus[child.name]?.status,
            };
          } else {
            newFileStatus[child.name] = {
              ...fileStatus[child.name],
              status:
                res.data.data[child.name]?.status ||
                fileStatus[child.name]?.status,
            };
          }
          if (res.data?.data[child.name]?.status === 'ERROR') {
            setProgress(0);
            throw new Error(res.data.message || 'Error while validating file');
          }
        });
        setZipFileStatus(prev => ({
          ...prev,
          ...newZipFileStatus,
        }));

        setFileStatus(prev => ({
          ...prev,
          ...newFileStatus,
        }));

        dispatch(
          setUploadedArtifacts({
            artifacts: extractSerializableFileProperties(files),
            missingFiles,
            dependentFiles,
            zipFileStatus: { ...zipFileStatus, ...newZipFileStatus },
            fileStatus: { ...fileStatus, ...newFileStatus },
          }),
        );
        if (missingArtifacts) {
          dispatch(
            setUploadedMissingArtifacts({
              artifacts: extractSerializableFileProperties(files),
              missingFiles,
              dependentFiles,
              zipFileStatus: { ...zipFileStatus, ...newZipFileStatus },
              fileStatus: { ...fileStatus, ...newFileStatus },
            }),
          );
        }
        setProgress(0);
      })
      .catch(err => {
        setProgress(0);
        console.log(err);
        axios
          .post(`${externalConfig.REACT_APP_API_URL}/file/shutdown`, {})
          .then(res => {
            console.log('Shutdown service called due to timeout');
          })
          .catch(err => console.log(err));
        // removeFile(file.name);
        SharedService.genericModalMessage =
          err.message || 'Error while validating file';
        dispatch(setActiveModal(EAppModal.FILE_VALIDATION_EXCEEDED));
        // throw err;
      });
  };

  const addFilesToList = async (fileSet: FileList) => {
    const newFiles = { ...files };
    const promise: Promise<any>[] = [];
    for (let i = 0; i < fileSet.length; i++) {
      const file = fileSet[i];
      newFiles[file.name] = getUploadFileInfo(file);
      promise.push(
        uploadFile(newFiles[file.name]).then(() => console.log('uploaded')),
      );
    }
    setFiles(newFiles);
    await Promise.all(promise);

    setTimeout(() => {
      scrollInformationCenterToEnd();
    }, 0);
  };
  const removeFile = (fileName: string) => {
    const url = `${externalConfig.REACT_APP_API_URL}/file/delete`;
    axios
      .post(url, { fileName: fileName })
      .then(res => {
        const newFiles = { ...files };
        delete newFiles[fileName];
        setFiles(newFiles);
        if (dependentFiles.includes(fileName)) {
          setDependentFiles([]);
          setMissingFiles([]);
        }
        setZipFileStatus(prev => ({ ...prev, [fileName]: null }));
        setFileStatus(prev => {
          const updatedStatus = { ...prev };
          delete updatedStatus[fileName];
          return updatedStatus;
        });
        dispatch(
          setUploadedArtifacts({
            artifacts: extractSerializableFileProperties(newFiles),
            missingFiles,
            dependentFiles,
            zipFileStatus,
            fileStatus,
          }),
        );
      })
      .catch(console.log);
  };
  async function uploadFile(file: UploadFile) {
    SharedService.genericModalMessage = '';
    if (file && !file.error) {
      try {
        const formData = new FormData();
        const url = `${externalConfig.REACT_APP_API_URL}/file/upload`;
        formData.append('file', file.originalFile);
        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: -1,
          onUploadProgress(progressEvent) {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              setProgress(percentCompleted);
            }
          },
        });

        if (response.data.status === 'ERROR') {
          throw new Error(response.data.message);
        }
      } catch (error: any) {
        (SharedService.genericModalMessage =
          error.message ??
          'It looks like we need more analysis to process the file.'),
          setProgress(0);
        throw error;
      }
    } else {
      throw new Error('File not found');
    }
  }
  async function handlePreValidation() {
    try {
      const response = await axios.post(
        `${externalConfig.REACT_APP_API_URL}/scan/pre-validation`,
      );
      if (response.data.status === 'ERROR') {
        setMissingFiles(response.data.data?.missingFiles || []);
        setDependentFiles(response.data.data?.uploadedFiles || []);
        throw new Error(response.data.message);
      } else {
        await startScan(dispatch);
      }
    } catch (err: any) {
      console.log('Error:', err?.message);
      SharedService.genericAlertModal.title = 'Artifact Validation Warning!';
      SharedService.genericAlertModal.description =
        err?.message || 'Something went wrong !';
      setTimeout(() => {
        dispatch(setActiveModal(EAppModal.GENERIC_ALERT_MODAL));
        scrollInformationCenterToEnd();
      }, 0);
    }
  }

  const toggleZipDetails = (fileName: string) => {
    setZipFileDetailsVisible(prevState => ({
      ...prevState,
      [fileName]: !prevState[fileName],
    }));
  };

  return (
    <div className="mx-auto p-2 pt-8">
      <div className="text-xl font-bold mb-4">Upload Your Files</div>

      <div className="mb-4">
        <div className="overflow-auto">
          {Object.values(files).map((file: UploadFile, index: number) => (
            <div
              key={index}
              className={`mb-2 border rounded-lg p-4 px-2 shadow ${uploadProgress[file.name]?.error ? 'border-red-500' : ''}`}
              style={{
                borderWidth: 1,
                borderColor: dependentFiles.includes(file?.name)
                  ? appTheme.colors.redBase
                  : appTheme.colors.greyCardOutline,
              }}
            >
              <div className="grid grid-cols-12 items-center">
                <div className="ml-2 h-10 w-10 flex items-center justify-center bg-gray-200 rounded-lg text-gray-700">
                  {file.type}
                </div>
                <div className="col-span-5 flex flex-col items-start">
                  <div className="flex-grow truncate text-[14px] font-semibold">
                    {file.name}
                  </div>
                  <div className="w-24 text-[14px]">{file.size}</div>
                </div>
                <div className="col-span-5"></div>
                <div className="flex justify-end mr-4">
                  <button className="ml-2 text-gray-700 hover:text-gray-900">
                    <span className="text-red-500">
                      {uploadProgress[file.name]?.uploadProgress >= 100 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          onClick={() => removeFile(file.name)}
                        >
                          <path
                            fillRule="evenodd"
                            d="M3 5a1 1 0 011-1h12a1 1 0 011 1v1H3V5zm14 4v8a2 2 0 01-2 2H5a2 2 0 01-2-2V9h14zm-4 1a1 1 0 10-2 0v6a1 1 0 102 0V10zm-4 0a1 1 0 10-2 0v6a1 1 0 102 0V10z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : validatingFiles &&
                        fileStatus[file.name].status === 'PENDING' ? (
                        <div>
                          <SVGLoader width={50} />
                        </div>
                      ) : (
                        <div
                          className="cursor-pointer"
                          onClick={() => removeFile(file.name)}
                        >
                          <SVGTrash />
                        </div>
                      )}
                    </span>
                  </button>
                </div>
              </div>

              {!!zipFileStatus[file.name] && (
                <>
                  <hr className="my-3" />
                  <div className="flex justify-between items-center">
                    <div
                      className={`mx-4 flex-shrink-0 text-[14px] font-bold ${
                        fileStatus[file.name]?.status === 'VALID'
                          ? 'text-green-500'
                          : fileStatus[file.name]?.status === 'PENDING' ||
                              fileStatus[file.name]?.status === 'TIME_OUT'
                            ? 'text-orange-500'
                            : 'text-redBase'
                      }`}
                    >
                      {fileStatus[file.name]?.status === 'VALID'
                        ? 'All Files Validated Successfully'
                        : fileStatus[file.name]?.status === 'PENDING'
                          ? `Validation Pending. Please click 'Validate To Proceed' button below.`
                          : fileStatus[file.name]?.status === 'TIME_OUT'
                            ? 'Timed out while Validation. Proceed Scanning'
                            : 'Some Files Failed Validation'}
                    </div>
                    {zipFileStatus[file.name]?.status !== 'PENDING' && (
                      <button
                        onClick={() => toggleZipDetails(file.name)}
                        className="bg-blue-500 text-white text-sm font-semibold py-1 px-4 rounded-full hover:bg-blue-700 transition duration-150 ease-in-out shadow-md"
                      >
                        {zipFileDetailsVisible[file.name]
                          ? 'Hide Details'
                          : 'Show Details'}
                      </button>
                    )}
                  </div>
                  {/* {zipFileDetailsVisible[file.name] && <hr className="my-3" />} */}
                  {zipFileDetailsVisible[file.name] && (
                    <div className="mx-2 my-2 mt-3 -mb-2">
                      <div className="my-4 p-6 bg-white rounded-lg shadow-sm border">
                        {/* <h3 className="text-md  mb-4 text-gray-800">
                          Summary of Files found in zip file
                        </h3> */}
                        <div className="grid grid-cols-3 gap-6 ">
                          <div className="text-center">
                            <span className="text-3xl font-bold text-blue-600">
                              {(
                                zipFileStatus[file.name]?.['totalSummary']?.jcl
                                  ?.length || 0
                              )
                                .toString()
                                .padStart(2, '0')}
                            </span>
                            <p className="text-sm text-gray-600 mt-2">JCL</p>
                          </div>
                          <div className="text-center">
                            <span className="text-3xl font-bold text-green-500">
                              {(
                                zipFileStatus[file.name]?.['totalSummary']
                                  ?.listings?.length || 0
                              )
                                .toString()
                                .padStart(2, '0')}
                            </span>
                            <p className="text-sm text-gray-600 mt-2">
                              LISTING
                            </p>
                          </div>
                          <div className="text-center">
                            <span className="text-3xl font-bold text-primary">
                              {(
                                zipFileStatus[file.name]?.['totalSummary']?.card
                                  ?.length || 0
                              )
                                .toString()
                                .padStart(2, '0')}
                            </span>
                            <p className="text-sm text-gray-600 mt-2">CARD</p>
                          </div>
                          <div className="text-center">
                            <span className="text-3xl font-bold text-indigoSmoothLight">
                              {(
                                zipFileStatus[file.name]?.['totalSummary']?.proc
                                  ?.length || 0
                              )
                                .toString()
                                .padStart(2, '0')}
                            </span>
                            <p className="text-sm text-gray-600 mt-2">PROC</p>
                          </div>
                          <div className="text-center">
                            <span className="text-3xl font-bold text-orangeAction">
                              {(
                                zipFileStatus[file.name]?.['totalSummary']?.dbrm
                                  ?.length || 0
                              )
                                .toString()
                                .padStart(2, '0')}
                            </span>
                            <p className="text-sm text-gray-600 mt-2">DBRM</p>
                          </div>
                          <div className="text-center">
                            <span className="text-3xl font-bold text-skyblueBase">
                              {(
                                zipFileStatus[file.name]?.['totalSummary']
                                  ?.others?.length || 0
                              )
                                .toString()
                                .padStart(2, '0')}
                            </span>
                            <p className="text-sm text-gray-600 mt-2">Others</p>
                          </div>
                        </div>
                      </div>
                      {/* <hr className="-mx-2" /> */}
                      <div className="mb-2">
                        {zipFileStatus[file.name].child?.['VALID'] && (
                          <FileSummarySection
                            title="Valid Files"
                            parentFile={file}
                            zipFileStatus={zipFileStatus}
                            status="Valid"
                            color={'rgba(219,244,210,.8)'}
                            statusColor={appTheme.colors.greenBase}
                            category="VALID"
                          />
                        )}
                        {zipFileStatus[file.name].child?.['NOT_VALID'] && (
                          <FileSummarySection
                            title="Invalid Files"
                            parentFile={file}
                            zipFileStatus={zipFileStatus}
                            status="Invalid"
                            color={appTheme.colors.redSmooth}
                            statusColor={appTheme.colors.redOnLight}
                            category="NOT_VALID"
                          />
                        )}
                        {zipFileStatus[file.name].child?.['IGNORED'] && (
                          <FileSummarySection
                            title="Ignored Files"
                            parentFile={file}
                            zipFileStatus={zipFileStatus}
                            status="Ignored"
                            color={appTheme.colors.grey20}
                            statusColor={appTheme.colors.darkGrey}
                            category="IGNORED"
                          />
                        )}
                        {zipFileStatus[file.name].child?.['FUTURESUPPORT'] && (
                          <FileSummarySection
                            title="Supported in future"
                            parentFile={file}
                            zipFileStatus={zipFileStatus}
                            status="Supported in future"
                            color={'rgba(255,223,214,.7)'}
                            statusColor={appTheme.colors.redBase}
                            category="FUTURESUPPORT"
                          />
                        )}
                        {zipFileStatus[file.name].child?.['TIME_OUT'] && (
                          <FileSummarySection
                            title="Validation Time Out"
                            parentFile={file}
                            zipFileStatus={zipFileStatus}
                            status="Timed Out"
                            color={'rgba(255,223,214,.7)'}
                            statusColor={appTheme.colors.redBase}
                            category="TIME_OUT"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
          {/* </div> */}
          {missingFiles.length > 0 && dependentFiles.length > 0 && (
            <div className="text-red-500 px-4  my-2 mt-4 py-4 bg-red-100 rounded-md">
              Please upload the following dependent files or delete the
              highlighted files from the list
              <div className="flex flex-row gap-2 justify-start">
                {missingFiles.map((file, index) => (
                  <span
                    className="text-sm px-4 py-[4px] bg-red-200 rounded-full mt-2"
                    key={index}
                  >
                    {file}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <input
          id="fileUpload"
          type="file"
          onChange={handleFileUpload}
          className="hidden"
          accept={`${VALID_FILE_TYPES.map(type => '.' + type).join(',')}`}
          multiple
        />
        <div
          style={{ display: 'none' }}
          className="border-2 border-dashed border-gray-300 p-2 text-center mb-4 cursor-pointer flex flex-col justify-center items-center"
          onDrop={handleDrop}
          onDragOver={event => event.preventDefault()}
          onClick={(event: any) => {
            event.currentTarget.parentElement.children[1].click();
          }}
        >
          <label
            htmlFor="fileUpload"
            className="p-4 text-xl text-center mb-2 cursor-pointer text-blue-500 font-bold"
          >
            Drag or click to upload file
            <div className="text-sm text-gray-500">
              {/* (Individual file size limit is 10MB) */}
            </div>
          </label>
        </div>
        <div
          className=" px-4 py-4 rounded-md"
          style={{ border: `1px solid ${appTheme.colors.grey20}` }}
        >
          <p>
            Upload JCLs, Listings, Control Cards, and Procedures to kickstart
            the process!
          </p>

          <div className="flex flex-row gap-4 items-center">
            <UploadButton
              disabled={!!uploadingFile || validatingFiles}
              handleFileUpload={handleFileUpload}
              accept={`${VALID_FILE_TYPES.map(type => '.' + type).join(',')}`}
              buttonStyle={{
                backgroundColor: `${validatingFiles ? 'gray' : '#1363DF'}`,
                cursor: `${validatingFiles ? 'not-allowed' : 'pointer'}`,
              }}
            />
            {/* <div className="mx-auto p-2 pt-8">
                <div className="text-xl font-bold mb-4">Upload Your files</div>
                  <button
                    className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => validateFiles(Object.values(files).map(f => f.originalFile))}
                    disabled={Object.keys(files).length === 0}
                  >
                    Validate All Files
                  </button>
            </div> */}
            {/* {Object.keys(files).length > 0 && !validatingFiles &&
              Object.values(fileStatus).some(status => status?.status === 'PENDING') && (                
              <ValidateButton
                onClick={()=>validateFile(Object.values(files).map(f => f.originalFile))}
                disabled = {Object.keys(files).length === 0}
              />
            )} */}
            {progress > 0 && (
              <div className="-ml-2 mt-[4px] flex flex-row gap-2 items-center">
                <RadialProgress total={100} currentProgress={progress} />
                <p className="text-[14px]">
                  {progress === 100 && validatingFiles
                    ? 'Validating Files'
                    : 'Uploading'}{' '}
                  {' ' + uploadingFile + '...'}
                </p>
              </div>
            )}
          </div>
        </div>
        {Object.keys(files).length > 0 &&
        uploadingFile === '' &&
        !validatingFiles &&
        Object.values(fileStatus).some(
          status => status?.status === 'PENDING',
        ) ? (
          <div className="flex justify-end mt-4 items-center gap-2">
            <HelpTextCallout calloutText={pageHelpText[0].description} />
            <button
              onClick={() =>
                validateFile(Object.values(files).map(f => f.originalFile))
              }
              disabled={Object.keys(files).length === 0}
              className="btn bg-cta hover:shadow-md text-white font-semibold py-2 px-6 rounded-full"
            >
              Validate To Proceed
            </button>
          </div>
        ) : (
          Object.keys(files).length > 0 &&
          Object.values(fileStatus).every(
            status => status?.status !== 'PENDING',
          ) && (
            <div className="flex justify-end mt-4 items-center gap-2">
              <HelpTextCallout calloutText={pageHelpText[1].description} />
              <button
                disabled={dependentFiles.length > 0}
                className="btn bg-cta hover:shadow-md text-white font-semibold py-2 px-6 rounded-full"
                style={{ opacity: dependentFiles.length > 0 ? 0.5 : 1 }}
                onClick={handlePreValidation}
              >
                Proceed
              </button>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default UploadList;
